import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
// import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'


import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import FamousPeople from '../modules/apps/famous-people-management/FamousPeople'
import FamousPeopleCategory from '../modules/apps/famous-people-category-management/FamousPeopleCategory'
import VitalSign from '../modules/apps/vital-sign-management/VitalSign'
import Diagnosis from '../modules/apps/diagnosis-management/Diagnosis'
import Ethnicity from '../modules/apps/ethnicity-management/Ethnicity'
import AhPartenrCategory from '../modules/apps/ah-partner-category-management/AhPartnerCategory'
import AhPartner from '../modules/apps/ah-partner-management/AhPartner'
import AhPartnerService from '../modules/apps/ah-partner-service-management/AhPartnerService'
import PackageMaster from '../modules/apps/package-master-management/PackageMaster'
import BodyParts from '../modules/apps/body-parts-management/BodyParts'
import Symptoms from '../modules/apps/symptoms-management/Symptoms'
import AnswerMaster from '../modules/apps/answer-master-management/AnswerMaster'
import QuestionMaster from '../modules/apps/question-master-management/QuestionMaster'
import RewardMaster from '../modules/apps/reward-master-management/RewardMaster'
import DashboardManagementPage from '../modules/apps/dashboard-management/DashboardManagementPage'

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const FeedbackManagement = lazy(
    () => import('../modules/apps/feedback-management/FeedbackManagementPage')
  )
  const NotificationsManagement = lazy(
    () => import('../modules/apps/notifications-management/NotificationsManagementPage')
  )
  const AppleADayManagement = lazy(
    () => import('../modules/apps/apple-a-day-management/AppleADayManagementPage')
  )
  const PartnerProductManagement = lazy(
    () => import('../modules/apps/partner-product-management/PartnerProductManagementPage')
  )
  const MenstrualCycleProduct = lazy(
    () => import('../modules/apps/menstrual-cycle-product/MenstrualCycleProductPage')
  )
  const AHPartners = lazy(() => import('../modules/apps/ah-new-partner-management/AHPartner'))
  const LimitedTimeOffer = lazy(
    () => import('../modules/apps/limited-time-offer-management/LimitedTimeOffer')
  )
  const MensHealthProduct = lazy(
    () => import('../modules/apps/mens-health-management/MensHealthProductPage')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardManagementPage />} />
        {/* <Route path="dashboard" element={<div>Dashboard Placeholder</div>} /> */}

        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/feedback-management/*'
          element={
            <SuspensedView>
              <FeedbackManagement />
            </SuspensedView>
          }
        />

        <Route
          path='apps/notifications-management/*'
          element={
            <Suspense fallback={<div>Loading...</div>}>
              {' '}
              {/* You can use SuspensedView if you have that component */}
              <NotificationsManagement />
            </Suspense>
          }
        />

        <Route
          path='apps/famous-peoples-category-management/*'
          element={
            <SuspensedView>
              <FamousPeopleCategory />
            </SuspensedView>
          }
        />
        <Route
          path='apps/famous-peoples-management/*'
          element={
            <SuspensedView>
              <FamousPeople />
            </SuspensedView>
          }
        />
        <Route
          path='apps/vital-sign-management/*'
          element={
            <SuspensedView>
              <VitalSign />
            </SuspensedView>
          }
        />
        <Route
          path='apps/diagnosis-management/*'
          element={
            <SuspensedView>
              <Diagnosis />
            </SuspensedView>
          }
        />
        <Route
          path='apps/ethnicity-management/*'
          element={
            <SuspensedView>
              <Ethnicity />
            </SuspensedView>
          }
        />
        <Route
          path='apps/ah-partner-category-management/*'
          element={
            <SuspensedView>
              <AhPartenrCategory />
            </SuspensedView>
          }
        />
        <Route
          path='apps/ah-partner-management/*'
          element={
            <SuspensedView>
              <AhPartner />
            </SuspensedView>
          }
        />
        <Route
          path='apps/ah-partner-service-management/*'
          element={
            <SuspensedView>
              <AhPartnerService />
            </SuspensedView>
          }
        />
        <Route
          path='apps/limited-time-offer-management/*'
          element={
            <SuspensedView>
              <LimitedTimeOffer />
            </SuspensedView>
          }
        />
        <Route
          path='apps/package-master-management/*'
          element={
            <SuspensedView>
              <PackageMaster />
            </SuspensedView>
          }
        />
        <Route
          path='apps/body-parts-management/*'
          element={
            <SuspensedView>
              <BodyParts />
            </SuspensedView>
          }
        />
        <Route
          path='apps/symptoms-management/*'
          element={
            <SuspensedView>
              <Symptoms />
            </SuspensedView>
          }
        />
        <Route
          path='apps/answer-master-management/*'
          element={
            <SuspensedView>
              <AnswerMaster />
            </SuspensedView>
          }
        />
        <Route
          path='apps/question-master-management/*'
          element={
            <SuspensedView>
              <QuestionMaster />
            </SuspensedView>
          }
        />
        <Route
          path='apps/reward-master-management/*'
          element={
            <SuspensedView>
              <RewardMaster />
            </SuspensedView>
          }
        />
        <Route
          path='apps/apple-a-day-management/*'
          element={
            <Suspense fallback={<div>Loading...</div>}>
              {/* You can use SuspensedView if you have that component */}
              <AppleADayManagement />
            </Suspense>
          }
        />
        <Route
          path='apps/partner-product-management/*'
          element={
            <Suspense fallback={<div>Loading...</div>}>
              {/* You can use SuspensedView if you have that component */}
              <PartnerProductManagement />
            </Suspense>
          }
        />
        <Route
          path='apps/menstrual-cycle-product/*'
          element={
            <Suspense fallback={<div>Loading...</div>}>
              {/* You can use SuspensedView if you have that component */}
              <MenstrualCycleProduct />
            </Suspense>
          }
        />
        <Route
          path='apps/mens-health-product/*'
          element={
            <Suspense fallback={<div>Loading...</div>}>
              {/* You can use SuspensedView if you have that component */}
              <MensHealthProduct />
            </Suspense>
          }
        />
        <Route
          path='apps/ah-new-partner-management/*'
          element={
            <SuspensedView>
              <AHPartners />
            </SuspensedView>
          }
        />
        {/* <Route
          path='apps/admin-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
